@import "../scss/variables";

$schemaFabSize: 40px;

.schema-fab {
  position: absolute;
  top: -$schemaFabSize/2;
  right: -$schemaFabSize/2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $schemaFabSize;
  height: $schemaFabSize;
  color: $white;
  border-radius: $schemaFabSize/2;;
  cursor: pointer;

  &.schema-fab-none {
    background-color: $primary;
    &:hover {
      background-color: lighten($primary, 7.5%);
    }
  }

  &.schema-fab-ens, &.schema-fab-ves, &.schema-fab-vae {
    background-color: $info;
    &:hover {
      background-color: lighten($info, 7.5%);
    }
  }
}

.schema-element {
  position: relative;
  cursor: default;
  user-select: none;
}

.schema-unite {
  &.schema-unite-mode-ves {
    border-left: 10px solid $yellow;
    border-color: $ves;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &.schema-unite-mode-vae {
    border-left: 10px solid $pink;
    border-color: $pink;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &.schema-unite-mode-ens {
    border-left: 10px solid $blue;
    border-color: $blue;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}

.schema-obtained {
  > .schema-unite, > .schema-choix, .schema-branches, > .schema-unites {
    border-left: 10px solid $success;
    border-color: $success;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}

.schema-unite {
  min-width: 100px;
  border: 1px solid $gray-600;
  margin-top: $schemaFabSize/2;
  padding: 0.2em;
  background-color: $white;
}

.schema-unite-actions {
  .schema-unite-action {
    cursor: pointer;
  }
}

.schema-legends {
  div {
    height: 15px;
    width: 15px;
  }
}

.schema-choix {
  border: 1px $gray-600 solid;
  padding: 0.5em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.schema-branches {
  margin-top: 0.5em;
  border: 1px $info solid;
  padding-left: 0.2em;
  padding-bottom: 0.5em;
  .schema-branche {
    padding-right: 0.2em;
  }
}

.schema-ensemble {
  margin-bottom: 0.5em;
}

.schema-unites {
  min-width: 100px;
  border: 1px solid $gray-600;
  margin-top: $schemaFabSize/2;
  padding: 0.2em;
  background-color: $white;

  .schema-unite {
    border-top: 0;
    border-bottom: 0;
    border-right: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left-width: 5px;
    margin-top: 0;
  }

  .schema-element:nth-child(2n) {
    .schema-unite::before {
      font-family: "Font Awesome 5 Free";
      content: "\f067";
      font-weight: 900;
      font-size: 1.2em;
    }
  }
}
.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 2000ms;
  transform: scale(1);
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.grow-in {
  animation: growButton 250ms;
}
@keyframes growButton {
  from {
    transform: scale(0, 1);
  }
  to {
    transform: scale(1, 1);
  }
}

.slide-from-left {
  overflow:hidden;
  animation-duration: .5s;
  animation-name: slideFromLeft;
}

@keyframes slideFromLeft {
  from {
    margin-right: 100%;
    width: 0;
  }

  to {
    margin-right: 0;
    width: 100%;
  }
}

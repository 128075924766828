.tree {
  .btn-no-style {
    padding: 0;
    margin: 0;
    border: 0;
    background-color: transparent;
    opacity: 0.5;
    transition: 0.5s;

    * {
      pointer-events: none;
    }

    &:focus {
      outline: 0;
    }

    &:hover {
      opacity: 1;
    }
  }

  label {
    filter: hue-rotate(315deg);
  }
}
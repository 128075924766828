// page
$max-format-tablet: 992px;

.cr-page {
  min-height: 100vh;
  margin: 60px 0 0 0;
  @media(min-width: $max-format-tablet) {
    margin: 0;
  }

  &__header {
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: flex-start;
    }
    display: flex;
    align-items: flex-end;
    align-self: center;
  }

  &__title {
    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
    }
    padding-right: 0.5rem;
    margin-bottom: 1rem;
  }


  .responsiveTitleCol {
    @media(max-width: $max-format-tablet) {
      flex: 1;
      max-width: none;
    }
  }
}
.listAutocomplete {
  width: 100%;
  position: relative;
  z-index: 25;

  ul {
    position: absolute;
    width: 100%;
    margin: 0;
    padding: 0;
    max-height: 50vh;
    overflow: auto;
    background-color: white;
    border: 1px solid #ced4da;
    border-top: 0;
  }

  li {
    list-style: none;
    padding: 0.375rem 0.75rem;
    cursor: pointer;

    &:nth-child(2n + 1){
      background-color: #fafafc;
    }
  }
}
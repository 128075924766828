.perimetersListManagement {
  display: flex;
  flex-direction: column;
  @media(min-width: 1100px) {
    flex-direction: row;
  }

  div {
    flex: 1;

    &:first-child {
      border: 1px solid #ced4da;

      label {
        filter: hue-rotate(315deg);
      }
    }

    &:last-child {
      border: 1px solid #ced4da;

      label {
        filter: hue-rotate(140deg);
      }
    }
  }

  ul {
    max-height: 30vh;
    min-height: 100px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0.3em;
    }

    &::-webkit-scrollbar-track {
      background-color: #fafafc;
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
    }

    li {
      list-style: none;
      background-color: white;
      user-select: none;
      cursor: pointer;

      &:nth-child(2n + 1){
        background-color: #fafafc;
      }
    }
  }

  .managementButtonsContainer{
    flex: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;

    @media(min-width: 1100px) {
      width: 30px;
    }

    .btn-primary, .btn-danger {
      text-align: center;
      padding: 10px 0;
      z-index: 5;
      transform: rotate(90deg);
      margin: 0 20px;
      min-width: 30px;
      @media(min-width: 1100px) {
        width: 100%;
        transform: none;
        margin: 5px 0;
      }
    }
  }
}

// Keeping the content of the project's sections scrollable "on top" of footer
.project-sections-wrap {
  min-height: 100%;
  margin-bottom: - var(--notes-footer-height);
}
.project-sections-wrap:after {
  content: "";
  display: block;
  height: var(--notes-footer-height);
}

// Making the editor scrollable
.ck-editor__editable_inline {
  min-height: 115px;
  max-height: 115px;
  overflow: scroll;
}
.ck-editor-view-mode {
  min-height: 115px;
  max-height: 115px;
  overflow: scroll;
}

.footer-notes {
  z-index: 999;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: var(--notes-footer-height);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  background-color: $white;
  filter: opacity(90%);

  transition: all .5s ease-in-out;

  @include media-breakpoint-down(lg) { left: 0; }
  @include media-breakpoint-up(lg) { left: var(--sidemenu-width); }
}

.btn-group-fab {
  position: fixed; // fixed to place at bottom right of page
  width: 50px;
  height: auto;
  right: 2.5%;
  bottom: 30px;
  z-index: 1000;
}

.btn-sub-title {
  display: none;
  position: absolute;
  border-radius: 8px;
}
.btn-sub-title.active {
  display: inline;
  position: absolute;
  width: max-content;
  height: auto;
  right: 50px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 8px;
}
.btn-group-fab div {
  position: relative; width: 100%;
  height: auto;
}

.btn-fab {
  position: absolute;
  bottom: 0;
  border-radius: 50%;
  display: block;
  width: 40px; height: 40px;
  margin: 4px auto;
}
.btn-main {
  width: 50px; height: 50px;
  right: 50%; margin-right: -25px;
  z-index: 9;
}
.btn-main.active.rotate {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}
.btn-group-fab .btn-sub {
  bottom: 0; z-index: 8;
  right: 50%;
  margin-right: -20px;
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.icon.rotate{
  -webkit-transition: all 750ms;
  transition: all 750ms;
  transform: rotate(-360deg);
  -webkit-transform: rotate(-360deg);
  -ms-transform: rotate(-360deg);
}
.icon.rotate.active {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
}

.btn-group-fab.active .btn-sub {
  $var: 60;
  // Start from 2 because 1st item is main button
  @for $i from 2 through 30 {
    &:nth-child(#{$i}) {
      bottom: #{$var}px;
      $var: $var + 50;
    }
  }
}

.fab-save {
  bottom: 100px;
}
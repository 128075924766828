@import "variables.scss";
@import "custom.scss";
@import '~bootstrap/scss/bootstrap';

@import "custom-forms";

@import "../components/page";
@import "../components/sidemenu";
@import "../components/tree";
@import "../components/fabButtons";
@import "../components/responsivemenu";
@import "../components/modal";
@import "../components/animations";
@import "../components/listAutocomplete";
@import "../components/perimetersListManagement";
@import "../components/formInputs";
@import "../components/schema";
@import "../components/enrol_option";
@import "../components/notification";
@import "../components/notes";

.enrol-option-active {
    border-left: 10px solid $ens;
    border-color: $ens;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &:first-child {
        border-top-left-radius: 10px;
    }

    &:last-child {
        border-bottom-left-radius: 10px;
    }
}
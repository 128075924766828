.cr-menuresponsive {
  width: 100%;
  position: fixed;
  max-height: 100vh;
  overflow: auto;
  top: 0;
  z-index: 1100;
  background: var(--colorFallBack); /* fallback for old browsers */
  background: linear-gradient(to top, var(--colorSecondary-responsive), var(--colorMain));

  &::after,
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: block;
    width: 100%;
    height: 100%;
    content: '';
  }

  &::before {
    background: #000;
    opacity: 0.33;
  }

  &::after {
    z-index: 3;
    // sublime light
    background: var(--colorFallBack); // fallback for old browsers
    background: linear-gradient(to top, var(--colorSecondary-responsive), var(--colorMain));
    opacity: 0.5;
  }

  &__nav-item {
    opacity: 1;
    padding: 0 8px;
    &.active {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }

  nav > li {
    list-style: none;
    padding-right: 1em;
  }

  &[data-image]:after {
    opacity: 0.77;
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0.33;
    background-position: top center;
    background-size: cover;
  }

  &__content {
    z-index: 4;
  }
}

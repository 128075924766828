.text-ves {
  .custom-control-input {

    &:checked ~ .custom-control-label::before {
      border-color: $ves;
      @include gradient-bg($ves);
    }

    &:focus ~ .custom-control-label::before {
      // the mixin is not used here to make sure there is feedback
      @if $enable-shadows {
        box-shadow: $input-box-shadow, $custom-control-indicator-focus-box-shadow;
      } @else {
        box-shadow: 0 0 0 $input-btn-focus-width rgba($ves, .25);
      }
    }

    &:focus:not(:checked) ~ .custom-control-label::before {
      border-color: $ves;
    }

    &:not(:disabled):active ~ .custom-control-label::before {
      background-color: $ves;
      border-color: $ves;
    }
  }

  .custom-radio {
    .custom-control-input:disabled {
      &:checked ~ .custom-control-label::before {
        @include gradient-bg($ves);
      }
    }
  }
}

.text-vae {
  .custom-control-input {

    &:checked ~ .custom-control-label::before {
      border-color: $vae;
      @include gradient-bg($vae);
    }

    &:focus ~ .custom-control-label::before {
      // the mixin is not used here to make sure there is feedback
      @if $enable-shadows {
        box-shadow: $input-box-shadow, $custom-control-indicator-focus-box-shadow;
      } @else {
        box-shadow: 0 0 0 $input-btn-focus-width rgba($vae, .25);
      }
    }

    &:focus:not(:checked) ~ .custom-control-label::before {
      border-color: $vae;
    }

    &:not(:disabled):active ~ .custom-control-label::before {
      background-color: $vae;
      border-color: $vae;
    }
  }

  .custom-radio {
    .custom-control-input:disabled {
      &:checked ~ .custom-control-label::before {
        @include gradient-bg($vae);
      }
    }
  }
}

.text-ens {
  .custom-control-input {

    &:checked ~ .custom-control-label::before {
      border-color: $ens;
      @include gradient-bg($ens);
    }

    &:focus ~ .custom-control-label::before {
      // the mixin is not used here to make sure there is feedback
      @if $enable-shadows {
        box-shadow: $input-box-shadow, $custom-control-indicator-focus-box-shadow;
      } @else {
        box-shadow: 0 0 0 $input-btn-focus-width rgba($ens, .25);
      }
    }

    &:focus:not(:checked) ~ .custom-control-label::before {
      border-color: $ens;
    }

    &:not(:disabled):active ~ .custom-control-label::before {
      background-color: $ens;
      border-color: $ens;
    }
  }

  .custom-radio {
    .custom-control-input:disabled {
      &:checked ~ .custom-control-label::before {
        @include gradient-bg($ens);
      }
    }
  }
}

.text-none {
  .custom-control-input {

    &:checked ~ .custom-control-label::before {
      border-color: $text-muted;
      @include gradient-bg($text-muted);
    }

    &:focus ~ .custom-control-label::before {
      // the mixin is not used here to make sure there is feedback
      @if $enable-shadows {
        box-shadow: $input-box-shadow, $custom-control-indicator-focus-box-shadow;
      } @else {
        box-shadow: 0 0 0 $input-btn-focus-width rgba($text-muted, .25);
      }
    }

    &:focus:not(:checked) ~ .custom-control-label::before {
      border-color: $text-muted;
    }

    &:not(:disabled):active ~ .custom-control-label::before {
      background-color: $text-muted;
      border-color: $text-muted;
    }
  }

  .custom-radio {
    .custom-control-input:disabled {
      &:checked ~ .custom-control-label::before {
        @include gradient-bg($text-muted);
      }
    }
  }
}

.btn-VAE {
  @include button-variant($background: white, $border: #e83e8c, $hover-background: #e83e8c, $hover-border:#e83e8c , $active-background: #e83e8c, $active-border: #e83e8c);
  color:#e83e8c;
}
.btn-formation {
  @include button-variant(white, #007bff, #007bff, #007bff,#007bff, #007bff);
  color:#007bff;
}
.btn-VES {
  &:hover {
    color:white !important;
  }
}
.btn-VES.active {
  color:white !important;
}

// Required
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// Include custom variable default overrides here
:root {
  // used for side and top menu gradiant overlay background
  --colorMain : #009688;                // Google TEAL 500
  --colorSecondary: #C5CAE9;            // Google INDIGO 100
  --colorSecondary-responsive: #80CBC4; // Google TEAL 200
  --colorFallBack: #4DB6AC;             // Google TEAL 300

  --sidemenu-width: 200px;
  --sidemenu-width-expanded: 200px;
  --sidemenu-width-collapsed: 0;

  --notes-footer-height: 250px;
  --notes-footer-height-expanded: 250px;
  --notes-footer-height-expanded-view-mode: 150px;
  --notes-footer-height-collapsed: 40px;

  --color-ROLE_ADMIN: #009688;
  --color-ROLE_CHARGE_AIOA_IP: #7986CB;
  --color-ROLE_LECTEUR: #6C757D;
}

$ens: $blue;
$ves: $yellow;
$vae: $pink;
$primary: #009688;
$info: #7986CB;

$theme-colors: (
        "primary": $primary, // TEAL 500
        "info": $info,    // INDIGO 300
        "success": #7cb342, // LIGHT GREEN 600
        "warning": #FBC02D, // YELLOW 500
        "danger": #Ef5350,  // RED 400
        "ens": $ens,
        "ves": $ves,
        "vae": $vae
);

$enable-rounded: false;

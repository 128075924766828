@import "variables";
@import "fonts";

body {
  font-family: 'Nunito', serif !important;
}
h1, h2, h3, h4, h5, h6, .nav-item {
  font-family: 'Montserrat', sans-serif !important;
}
h1, h2, h3, h4, .nav-item {
  text-transform: uppercase;
}

.nonmodal{
  top:10%;
  left:50%;
  bottom:auto;
  right:auto;
  margin-left:-300px;
  position: fixed;
  overflow-x: hidden;
  overflow-y: auto;
}
.modal-open{
  overflow: unset;
}
.modal {
  .modal-dialog{
    overflow-y: initial;
    width: 80%;
    .modal-header{
      background-color: #288472;
      color:white;
      .close{
        color:white;
      }
    }
    .modal-body{
      max-height: 35em;
      overflow-y: auto;
    }
    &.fullscreen {
      max-height: inherit;
      height: 100%;
      padding: 0;
      width: fit-content;
      max-width: inherit;
      .modal-body {
        max-height: inherit;
        height: fit-content;
      }
      img {
        width: 100%;
        max-width: 1200px;
      }
    }
  }
  .react-draggable{
    position: fixed;
  }
}

@media (max-width: 780px)  {
  .nonmodal{
    margin-left: -40%;

  }
}

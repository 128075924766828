.cr-sidemenu {
  position: static;
  top: 0;
  bottom: 0;
  @media(max-width: 599px)  {
    left: - var(--sidemenu-width-xs);
    width: var(--sidemenu-width-xs);
  }
  @media(min-width: 600px) {
    left: - var(--sidemenu-width);
    width: var(--sidemenu-width);
  }
  z-index: 5;
  font-weight: 200;
  color: #fff;
  background-position: center center;
  background-size: cover;
  transition: left 0.1s ease-in;

  + .cr-content {
    transition: margin 0.1s ease-in;
  }

  &::after,
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: block;
    width: 100%;
    height: 100%;
    content: '';
  }

  &::before {
    background: #000;
    opacity: 0.33;
  }

  &::after {
    z-index: 3;
    // sublime light
    background: var(--colorFallBack); /* fallback for old browsers */
    background: linear-gradient(to top, var(--colorSecondary), var(--colorMain));
    opacity: 1;
  }

  &[data-image]:after {
    opacity: 0.77;
  }

  &__content {
    position: relative;
    z-index: 4;
    height: calc(100vh - 20px);
    padding-bottom: 30px;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
  }

  &__nav-item-collapse {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }

  //&__nav-item-icon {
  //  width: 1.5rem;
  //  height: 1.5rem;
  //  padding-right: 0.5rem;
  //}

  &--open {
    left: 0;
    transition: left 0.2s ease-in;

    + .cr-content {
      margin: 0;
      @media(min-width: 992px) {
        margin: 0 0 0 var(--sidemenu-width);
      }
      transition: margin 0.2s ease-in;
    }
  }

  .nav {
    margin-top: 20px;

    .nav-item {
      margin: 5px 10px;
      color: #FFF;
      border-radius: 4px;
      opacity: 0.85;
    }

    .nav-link {
      color: #FFF;
      &:hover {
        font-weight: bolder;
        opacity: 1;
      }
    }

  }

  .nav-item {
    .nav-link {
      display: flex;
      align-items: center;

      &.active {
        color: #fff;
        background: rgba(255, 255, 255, .25);
        opacity: 1;
        font-weight: bolder;
      }
      &:hover {
        font-weight: bolder;
        opacity: 1;
      }
    }
  }

  .sidemenu .nav a {
    transition: all 150ms ease-in;
  }
}

#cr-menu-toggle-button {
  @extend .d-flex;
  @extend .justify-content-center;
  @extend .align-items-center;
  @extend .shadow;
  @extend .text-primary;
  @extend .position-absolute;
  @extend .bg-white;

  top: 10px;
  left: 187px;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  z-index: 105;
  cursor: pointer;

  &.is-collapse {
    @extend .bg-primary;
    @extend .text-white;
    left: 150px;

    &:hover {
      background-color: lighten($primary, 7.5%);
    }
  }

  &:hover {
    @extend .bg-primary;
    @extend .text-white;
  }
}

.cr-collpasedMenuContainer {
  display: none;
  @media(min-width: 992px) {
    display: block;
  }

  .navbar {
    @extend .bg-light;
    border-bottom-right-radius: 70%;
  }

  .cr-collpasedMenu {
    height: 100vh;
    padding-top: 3rem;

    a.nav-link {
      display: flex;
      align-content: flex-start;
      padding: 1rem;
      padding-right: 5px;
      color: $primary;

      .cr-collpasedMenuLabel {
        position: relative;
        overflow: hidden;
        opacity: 0;
        max-width: 0;
        transition: opacity 0.5s;

        //Popup with label for the menu
        &:before {
          content: '';
          display : inline-block;
          position: absolute;
          top: 5px;
          left: 1px;
          height : 0;
          width : 0;
          border-top : 7px solid transparent;
          border-right : 8px solid rgba(0, 0, 0, 0.2);
          border-bottom : 7px solid transparent;
        }
        &:after {
          content: '';
          display : inline-block;
          position: absolute;
          left: 3px;
          top: 5px;
          height : 0;
          width : 0;
          border-top : 7px solid transparent;
          border-right : 8px solid white;
          border-bottom : 7px solid transparent;
        }
        p {
          position: absolute;
          top: -0.6rem;
          left: 8px;
          white-space: nowrap;
          margin: 0;
          padding: 0.5rem 1rem;
          background-color: white;
          border: 1px solid rgba(0, 0, 0, 0.2);
        }
      }

      &:hover {
        .cr-collpasedMenuLabel {
          opacity: 1;
          overflow: inherit;
          max-width: none;
        }
      }
    }
  }
}
//MultipleEmails
.inputMultipleEmailsContainer {
  border-bottom: 1px solid #ced4da;
}

.presentEmailContainer {
  background-color: rgba(0, 150, 136, 0.07);
  margin-right: 10px;
  border-radius: 17px;
  margin-bottom: 10px;
  height: 34px;

  .presentEmail {
    flex: 1;
    padding: 5px 5px 5px 15px;
  }

  button {
    width: 34px;
    height: 34px;
    border: 0;
    border-radius: 17px;
    background-color: transparent;
    transition-duration: 0.5s;

    &:hover {
      background-color: rgba(0, 150, 136, 0.15);
    }
    &:focus {
      outline: 0;
    }
  }
}

.inputMultipleEmail {
  border: 0;
  width: auto;
  min-width: 30%;
}